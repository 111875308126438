import { service } from '@/service'
import {
  IBanner,
  IBetBonusActivityConfig,
  IBetBonusActivityRes,
  IDailyCheckInByUserRes,
  IDailyCheckInConfigRes,
  IDailyCheckInRuleRes,
  IDepositActivityConfig,
  IDepositActivityForUser,
  IEuropeanPreheatInfoRes,
  IGetEuroPreheatRewardsReq,
  IRebateActivityConfig,
  IReferralRally,
  IReferralRallyConfig,
  IReferralRallyDetail,
  IReferralRallyRules,
  IShareJackpotRule,
  ISlotBetBonusActivityConfig,
  ISlotBetBonusActivityRes,
  ISubmitCommonActivityReq,
  IGetEuropeanCupPreheatBasicInfoResponse,
  IGetEuroCupPenaltyKickConfigResponse,
  IGetEuroCupPenaltyKickDetailResponse,
  IGetEuroCupPenaltyKickRuleResponse,
  IGetEuroCupPenaltyKickRuleReuqest,
  ISubmitPenaltyKickResponse,
  ISubmitPenaltyKickReuqest,
  PromotionAPI,
  IPromotionList,
  IGuessingActivityConfig,
  IActivityBetDetail,
  IGuessItem,
  IGetMidAutumnActivityListReuqest,
  IApplySportsBettingReq,
  IGetBalanceInterestConfigResponse,
  IGetBetCompensationResponse,
  IGetEuroLeagueProfitResponse,
  IGetLuckyOrderRewardsRequest,
  IGetApplyLuckyOrderRequest
} from './types'

// 首页banner列表
export function getBanner() {
  return service.get<IBanner[]>({
    url: PromotionAPI.GetBanner
  })
}

// 获取充值活动配置
export function getDepositActivityConfig() {
  return service.get<IDepositActivityConfig>({
    url: PromotionAPI.GetDepositActivityConfig
  })
}

// 获取用户充值活动信息
export function getDepositActivityForUser() {
  return service.get<IDepositActivityForUser>({
    url: PromotionAPI.GetDepositActivityForUser
  })
}

// 申请充值活动
export function submitDepositActivity(data: ISubmitCommonActivityReq) {
  return service.post({
    url: PromotionAPI.SubmitDepositActivity,
    data
  })
}

// 获取瓜分大奖配置
export function getShareJackpotRules() {
  return service.get<IShareJackpotRule[]>({
    url: PromotionAPI.GetShareJackpotRules
  })
}

// 获取返水活动配置
export function getRebateActivityConfig() {
  return service.get<IRebateActivityConfig[]>({
    url: PromotionAPI.GetRebateActivityConfig
  })
}

// 获取每周投注送奖金活动配置
export function getBetBonusActivityConfig() {
  return service.get<IBetBonusActivityConfig[]>({
    url: PromotionAPI.GetBetBonusActivityConfig
  })
}

// 获取电子救济金活动配置
export function getSlotBetBonusActivityConfig() {
  return service.get<ISlotBetBonusActivityConfig>({
    url: PromotionAPI.GetSlotBetBonusActivityConfig
  })
}

// 获取用户每周投注送奖金活动信息
export function getBetBonusActivity() {
  return service.get<IBetBonusActivityRes>({
    url: PromotionAPI.GetBetBonusActivity
  })
}

// 申请每周投注送奖金活动奖金
export function submitBetBonusActivity(data: ISubmitCommonActivityReq) {
  return service.post({
    url: PromotionAPI.SubmitBetBonusActivity,
    data
  })
}

// 获取用户电子救济金活动信息
export function getSlotBetBonusActivity() {
  return service.get<ISlotBetBonusActivityRes>({
    url: PromotionAPI.GetSlotBetBonusActivity
  })
}

// 申请电子救济金活动奖金
export function submitSlotBetBonusActivity(data: ISubmitCommonActivityReq) {
  return service.post({
    url: PromotionAPI.SubmitSlotBetBonusActivity,
    data
  })
}

// 获取充值活动文案配置
export function getDepositActivityCopywriting(res: string) {
  return service.get({
    url: PromotionAPI.GetDepositActivityCopywriting,
    params: {
      coins: res
    }
  })
}

export function getDepositRules() {
  return service.get({
    url: PromotionAPI.GetDepositActivityCopywriting,
    params: {
      coins: 'VND'
    }
  })
}

// 获取每日签到活动配置
export function getDailyCheckInConfig() {
  return service.get<IDailyCheckInConfigRes>({
    url: PromotionAPI.GetDailyCheckInConfig
  })
}

// 获取用户每日签到详情
export function getDailyCheckInByUser() {
  return service.get<IDailyCheckInByUserRes>({
    url: PromotionAPI.GetDailyCheckInByUser
  })
}

// 用户参与每日签到
export function applyDailyCheckIn() {
  return service.post({
    url: PromotionAPI.ApplyDailyCheckIn
  })
}

// 获取每日签到活动规则
export function getDailyCheckInRule(params: { coins: string }) {
  return service.get<IDailyCheckInRuleRes[]>({
    url: PromotionAPI.GetDailyCheckInRule,
    params
  })
}

// 获取用户好友邀请信息-获取邀请链接
export function getReferralRally() {
  return service.get<IReferralRally>({ url: PromotionAPI.GetReferralRally })
}

// 获取好友邀请活动规则
export function getReferralRallyRule(params: { coins: string }) {
  return service.get<IReferralRallyRules>({ url: PromotionAPI.GetReferralRallyRule, params })
}

// 获取好友邀请活动配置
export function getReferralRallyConfig() {
  return service.get<IReferralRallyConfig>({ url: PromotionAPI.GetReferralRallyConfig })
}

// 领取好友邀请奖励
export function getReceiveReferralRallyReward(params: { rewardId: number }) {
  return service.post({ url: `${PromotionAPI.ReceiveReferralRallyReward}?rewardId=${params.rewardId}` })
}

// 查询用户邀请详情
export function getReferralRallyDetail() {
  return service.get<IReferralRallyDetail>({ url: PromotionAPI.GetReferralRallyDetail })
}

// 欧洲杯预热活动-活动信息
export function getEuropeanPreheatInfo() {
  return service.get<IEuropeanPreheatInfoRes>({ url: PromotionAPI.GetEuropeanPreheatInfo })
}

// 参与欧洲杯预热活动
export function applyEuropeanPreheat() {
  return service.post({ url: PromotionAPI.ApplyEuropeanPreheat })
}

// 领取欧洲杯预热活动奖励
export function getEuroPreheatRewards(data: IGetEuroPreheatRewardsReq) {
  return service.post({ url: PromotionAPI.GetEuroPreheatRewards, data })
}
// 获取欧洲杯预热活动基本信息
export function getEuropeanCupPreheatBasicInfo() {
  return service.get<IGetEuropeanCupPreheatBasicInfoResponse>({ url: PromotionAPI.GetEuropeanCupPreheatBasicInfo })
}
// 获取欧洲杯点球大战活动规则
export function getEuroCupPenaltyKickRule(data: IGetEuroCupPenaltyKickRuleReuqest) {
  return service.get<IGetEuroCupPenaltyKickRuleResponse[]>({ url: PromotionAPI.GetEuroCupPenaltyKickRule, params: data })
}
// 获取欧洲杯点球大战活动配置
export function getEuroCupPenaltyKickConfig() {
  return service.get<IGetEuroCupPenaltyKickConfigResponse>({ url: PromotionAPI.GetEuroCupPenaltyKickConfig })
}
// 获取欧洲杯点球大战活动详情
export function getEuroCupPenaltyKickDetail() {
  return service.get<IGetEuroCupPenaltyKickDetailResponse>({ url: PromotionAPI.GetEuroCupPenaltyKickDetail })
}
// 提交欧洲杯点球大战活动
export function submitPenaltyKick(data: ISubmitPenaltyKickReuqest) {
  return service.post<ISubmitPenaltyKickResponse>({ url: PromotionAPI.SubmitPenaltyKick, params: data })
}
// 领取欧洲杯点球大战活动奖励
export function getPenaltyKickRewards() {
  return service.post({ url: PromotionAPI.GetEuroCupPenaltyKickReward })
}
// 活动列表
export function getPromoList() {
  return service.post<IPromotionList>({ url: PromotionAPI.GetPromotionList })
}

// 竞猜 - 查询竞猜活动配置-全部-非token
export function getGuessingActivityConfig() {
  return service.get<IGuessingActivityConfig>({ url: PromotionAPI.GetGuessingActivityConfig })
}

// 竞猜 - 获取指定活动规则
export function SelectActivityRule(params: { activityType: number }) {
  return service.get<IDailyCheckInRuleRes>({ url: PromotionAPI.SelectActivityRule, params })
}

// 竞猜 - 查询竞猜活动详情
export function getGuessActivityBetDetail() {
  return service.get<IActivityBetDetail>({ url: PromotionAPI.GetGuessActivityBetDetail })
}

// 竞猜 - 参与竞猜-投票指定队伍
export function voteGuess(params: { guessType: number; teamId: number }) {
  return service.post<any>({ url: `${PromotionAPI.VoteGuess}?guessType=${params.guessType}&teamId=${params.teamId}` })
}

// 竞猜 - 查询竞猜活动配置-所有球队投票信息
export function getGuessActivityAll() {
  return service.get<IGuessItem[]>({ url: PromotionAPI.GetGuessActivityAll })
}

// 中秋 - 查询中秋活动配置
export function getMidAutumnActivityConfig() {
  return service.get<any>({ url: PromotionAPI.GetMidAutumnActivityConfig })
}

// 查询中秋活动分页数据 post
export function getMidAutumnActivityList(params: IGetMidAutumnActivityListReuqest) {
  return service.post<any>({ url: PromotionAPI.GetMidAutumnActivityList, data: params })
}

// 查询当前用户-中秋活动数据
export function getMidAutumnActivityUser(params: { statisticDate: number }) {
  return service.get<any>({ url: PromotionAPI.GetMidAutumnActivityByUser, params })
}

// 参与体育投注活动
export function applySportsBetting(params: IApplySportsBettingReq) {
  return service.post({ url: PromotionAPI.ApplySportsBetting, data: params })
}

// 体育投注活动配置
export function getSportsBettingConfig() {
  return service.post({ url: PromotionAPI.GetSportsBettingConfig })
}

//参与余额宝活动
export function applyBalanceInterest() {
  return service.get<any>({ url: PromotionAPI.ApplyBalanceInterest })
}

//余额宝活动配置
export function getBalanceInterestConfig() {
  return service.get<IGetBalanceInterestConfigResponse>({ url: PromotionAPI.GetBalanceInterestConfig })
}

// 电子幸运单活动配置
export function getLuckyOrderConfig() {
  return service.post({ url: PromotionAPI.GetLuckyOrderConfig })
}

//电子幸运单奖励列表
export function getLuckyOrderRewards(params: IGetLuckyOrderRewardsRequest) {
  return service.post<any>({ url: PromotionAPI.GetLuckyOrderRewards, data: params })
}

// 领取-电子幸运单
export function applyLuckyOrder(params: IGetApplyLuckyOrderRequest) {
  return service.post({ url: PromotionAPI.ApplyLuckyOrder, data: params })
}
//首单包赔活动配置
export function getBetCompensationConfig() {
  return service.get<IGetBetCompensationResponse>({ url: PromotionAPI.GetBetCompensationConfig })
}

//参与首单包赔活动
export function applyBetCompensation() {
  return service.get<any>({ url: PromotionAPI.ApplyBetCompensation })
}

//欧冠每日负盈利活动配置
export function getEuroLeagueProfitConfig() {
  return service.get<IGetEuroLeagueProfitResponse>({ url: PromotionAPI.GetEuroLeagueProfitConfig })
}

// 领取欧冠每日负盈利
export function applyEuroLeagueProfit(data: { id: number }) {
  return service.post({ url: PromotionAPI.ApplyEuroLeagueProfit, data: data })
}
