<script setup lang="ts">
import useAppStore from '@/store/app'
import useUserStore from '@/store/user'
import { deviceInfo, ID_KEYS, NATIVE_METHODS, sendToNative } from '@/utils/native'
import { initFingerPrint } from '@/utils/fingerprint'
import LottieLoading from './components/LottieLoading.vue'
import { onMounted, watch } from 'vue'
import useCustomerStore from './store/customer'
import recordEvent from '@/utils/recordEvent'
import { initDataReport } from '@/utils/dataReport'
import BaseLayout from '@/layout/BaseLayout.vue'
import { useRoute } from 'vue-router'
import NoticePopup from '@/views/home/components/NoticePopup.vue'

const appStore = useAppStore()
const useStore = useUserStore()
const customerStore = useCustomerStore()
const route = useRoute()

if (!deviceInfo.isBrowser) {
  sendToNative({
    method: NATIVE_METHODS.GET_USERINFO,
    id: ID_KEYS.GET_USERINFO
  })
}

// 获取app状态栏和底部安全区域高度
appStore.getDeviceExtraLayout()

// 调用 initFingerPrint 函数，初始化指纹
initFingerPrint()

initDataReport()

watch(
  () => appStore.deviceId,
  (value) => {
    if (!value) return

    window.gtag('config', 'G-QLFVF1H4VC', {
      deviceId: value,
      agent: window.vipAgents ? window.vipAgents[0]?.name : '',
      userId: useStore.userInfo?.id
    })
  },
  {
    immediate: true
  }
)
watch(
  () => useStore.userInfo.id,
  (value) => {
    console.log('userStore.userInfo.id', value)
    window.gtag('config', 'G-QLFVF1H4VC', {
      userId: value
    })
  }
)

if (useStore.isLogin) {
  useStore.checkStatus()
}

// 调用 appStore 的 set100vh 方法，设置 CSS 变量 --vh 的值，使其等于视口的高度
appStore.set100vh()

onMounted(() => {
  // 添加一个事件监听器，当窗口大小改变时，更新 CSS 变量 --vh 的值
  window.addEventListener('resize', () => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`)
  })
  customerStore.hideBubble()
})

if (!window.localStorage.getItem('openBefore')) {
  // 首次打开埋点事件
  recordEvent.record('firstOpen')
  window.localStorage.setItem('openBefore', 'true')
} else {
  // 非首次打开埋点事件
  recordEvent.record('open')
}
</script>

<template>
  <BaseLayout :show-back="route.meta?.showBack as boolean" :tabs="route.meta?.tabs as []">
    <router-view />

    <NoticePopup />
  </BaseLayout>
  <LottieLoading v-show="appStore.loading" />
</template>

<style scoped lang="scss">
/* .slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
} */
</style>
